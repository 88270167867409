










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class EditorSidebarRight extends Vue {
  @Prop({
    required: false,
    default: false,
  })
  protected visible!: boolean;

  protected showSidebar = false;

  @Watch('visible')
  protected handleVisibilityChange(newVal: boolean): void {
    this.showSidebar = newVal;
  }

  @Watch('showSidebar')
  protected handleShowSidebarChange(newVal: boolean): void {
    this.$emit('visibility-change', newVal);
  }
}
