































import { Component, Prop } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import Model from '@/models/Model';
import ModelConfig from '@/models/ModelConfig';
import PreviewCanvas from '@/components/Editor/PreviewCanvas.vue';
import ModelElement from '@/models/ModelElement';
import Node from '@/models/Node';
import { fixNodeReferencesOfEdges } from '@/serializer/helpers';
import ChildrenSelectTable from '@/components/Editor/ModelElementImport/ChildrenSelectTable.vue';
import NodeHelpers from '@/serializer/NodeHelpers';

@Component({
  components: { ChildrenSelectTable, PreviewCanvas },
})
export default class ModelElementSelectModal extends Vue {
  public static MODAL_ID = 'modal-modelelement-select';

  @Prop({
    required: true,
  })
  protected modelElement!: ModelElement;

  @Prop({
    required: true,
  })
  protected model!: Model;

  @Prop({
    required: true,
  })
  protected modelConfig!: ModelConfig;

  protected selectedModelElements: ModelElement[] = [];

  protected importEdges = true;

  get id(): string {
    return ModelElementSelectModal.MODAL_ID;
  }

  get childrenOptions(): { id: string; name: string; type: string }[] {
    return this.children.map((child) => {
      return {
        id: child.id,
        type: child.type,
        name: NodeHelpers.getName(child),
      };
    });
  }

  get children(): ModelElement[] {
    if (this.modelElement && this.model) {
      return this.model.modelElements.filter(
        (modelElement) => (modelElement as Node).parentId === this.modelElement.id
      );
    }

    return [];
  }

  get hasParent(): boolean {
    if (this.modelElement) {
      if (this.modelElement instanceof Node) {
        return typeof (this.modelElement as Node).parentId === 'string';
      }
    }

    return false;
  }

  get selectedModelElementIds(): string[] {
    return this.selectedModelElements.map((modelElement) => modelElement.id).concat([this.modelElement.id]);
  }

  get modelElementIds(): string[] {
    return this.children.map((modelElement) => modelElement.id).concat([this.modelElement.id]);
  }

  get selectedEdges(): ModelElement[] {
    if (this.modelElement && this.model) {
      return this.model.edges.filter(
        (edge) =>
          edge.connectsToId &&
          edge.connectsFromId &&
          this.selectedModelElementIds.indexOf(edge.connectsFromId) > -1 &&
          this.selectedModelElementIds.indexOf(edge.connectsToId) > -1
      );
    }

    return [];
  }

  get edges(): ModelElement[] {
    if (this.modelElement && this.model) {
      return this.model.edges.filter(
        (edge) =>
          edge.connectsToId &&
          edge.connectsFromId &&
          this.modelElementIds.indexOf(edge.connectsFromId) > -1 &&
          this.modelElementIds.indexOf(edge.connectsToId) > -1
      );
    }

    return [];
  }

  get hasEdges(): boolean {
    return this.edges.length > 0;
  }

  get hasChildren(): boolean {
    return this.children.length > 0;
  }

  get selectedRows(): { id: string; name: string; type: string }[] {
    return this.selectedModelElements.map((modelElement) => {
      return {
        id: modelElement.id,
        type: modelElement.type,
        name: NodeHelpers.getName(modelElement),
      };
    });
  }

  protected handlePreviewModelelement({ modelElementId }: { modelElementId: string }): void {
    this.$emit('preview-modelelement', { modelElementId, modelConfig: this.modelConfig });
  }

  protected handleSelectModelelement({
    modelElementDetails,
  }: {
    modelElementDetails: { id: string; type: string; name: string };
  }): void {
    let modelElement = this.model.modelElements.find((modelElement) => modelElement.id === modelElementDetails.id);
    if (modelElement && this.selectedModelElements.indexOf(modelElement) === -1) {
      this.selectedModelElements.push(modelElement);
    }
  }

  protected handleDeselectModelelement({
    modelElementDetails,
  }: {
    modelElementDetails: { id: string; type: string; name: string };
  }): void {
    let modelElement = this.model.modelElements.find((modelElement) => modelElement.id === modelElementDetails.id);
    if (modelElement) {
      let index = this.selectedModelElements.indexOf(modelElement);
      if (index > -1) {
        this.selectedModelElements.splice(index, 1);
      }
    }
  }

  protected handleSelect(): void {
    let modelElements = [this.modelElement].concat(this.selectedModelElements);
    if (this.importEdges) {
      modelElements = modelElements.concat(this.selectedEdges);
    }
    this.$emit('select', { modelElements });

    this.selectedModelElements = [];
  }
}
