


















import { Component, Prop } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import Model from '@/models/Model';
import ModelConfig from '@/models/ModelConfig';
import PreviewCanvas from '@/components/Editor/PreviewCanvas.vue';
import ModelElement from '@/models/ModelElement';
import Node from '@/models/Node';
import { fixNodeReferencesOfEdges } from '@/serializer/helpers';
import Edge from '@/models/Edge';

@Component({
  components: { PreviewCanvas },
})
export default class ModelElementPreviewModal extends Vue {
  public static MODAL_ID = 'modal-modelelement-preview';

  @Prop({
    required: true,
  })
  protected modelElementId!: string;

  @Prop({
    required: true,
  })
  protected model!: Model;

  @Prop({
    required: true,
  })
  protected modelConfig!: ModelConfig;

  protected showParent = false;
  protected showChildren = false;
  protected showParentChildren = true;

  get id(): string {
    return ModelElementPreviewModal.MODAL_ID;
  }

  get hasParent(): boolean {
    if (this.modelElementId) {
      let modelElement = this.model.modelElements.find((modelElement) => modelElement.id === this.modelElementId);

      if (modelElement instanceof Node) {
        return typeof (modelElement as Node).parentId === 'string';
      }
    }

    return false;
  }

  get hasChildren(): boolean {
    if (this.modelElementId) {
      let modelElement = this.model.modelElements.filter(
        (modelElement) => (modelElement as Node).parentId === this.modelElementId
      );

      return modelElement.length > 0;
    }

    return false;
  }

  get modelElements(): (ModelElement | undefined)[] {
    let modelElements: ModelElement[] = [];

    if (this.model) {
      // find original preview item
      let originalPreviewItem = this.model.modelElements.find(
        (modelElement) => modelElement.id === this.modelElementId
      );

      // add parent
      let parent: ModelElement | null | undefined = null;
      if (this.showParent && originalPreviewItem instanceof Node) {
        parent = this.model.modelElements.find(
          (modelElement) => modelElement.id === (originalPreviewItem as Node).parentId
        );
        if (parent) {
          modelElements.push(parent);
        }
      }

      // add neighbors
      if (this.showParent && this.showParentChildren && parent) {
        let parentChildren: ModelElement[] = [];
        this.model.modelElements.forEach((modelElement) => {
          if (parent && modelElement instanceof Node && (modelElement as Node).parentId === parent.id) {
            if (originalPreviewItem && modelElement.id !== originalPreviewItem.id) {
              parentChildren.push(modelElement);
            }
          }
        });

        modelElements = modelElements.concat(parentChildren);
      }

      // add children
      if (this.showChildren && originalPreviewItem instanceof Node) {
        let children: ModelElement[] = [];
        this.model.modelElements.forEach((modelElement) => {
          if (
            originalPreviewItem &&
            modelElement instanceof Node &&
            (modelElement as Node).parentId === originalPreviewItem.id
          ) {
            if (modelElement.id !== originalPreviewItem.id) {
              children.push(modelElement);
            }
          }
        });

        modelElements = modelElements.concat(children);
      }

      // if edge is selected show source and target node
      if (originalPreviewItem instanceof Edge) {
        let sourceNode = this.model.modelElements.find(
          (modelElement) => (originalPreviewItem as Edge).connectsFromId === modelElement.id
        );
        let targetNode = this.model.modelElements.find(
          (modelElement) => (originalPreviewItem as Edge).connectsToId === modelElement.id
        );
        // let nodes: ModelElement[] = this.model.modelElements.filter((modelElement) => (originalPreviewItem as Edge).connectsFromId === modelElement.id || (originalPreviewItem as Edge).connectsToId === modelElement.id);
        if (sourceNode) {
          modelElements.push(sourceNode);
        }
        if (targetNode) {
          modelElements.push(targetNode);
        }
      }

      // add original item to prevent z-index error
      if (originalPreviewItem) {
        modelElements.push(originalPreviewItem);
      }

      // add edges
      let modelElementIds: string[] = modelElements.map((modelElement) => modelElement.id);
      let modelEdges = fixNodeReferencesOfEdges(this.model);
      let edges: ModelElement[] = modelEdges.edges.filter(
        (edge) =>
          edge.connectsToId &&
          edge.connectsFromId &&
          modelElementIds.indexOf(edge.connectsToId) > -1 &&
          modelElementIds.indexOf(edge.connectsFromId) > -1 &&
          // prevent duplicate
          modelElementIds.indexOf(edge.id) === -1
      );

      modelElements = modelElements.concat(edges);
    }

    return modelElements;
  }
}
