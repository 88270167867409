



































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ModelConfig from '@/models/ModelConfig';
import ModelElement from '@/models/ModelElement';
import Point from '@/models/Point';
import Node from '@/models/Node';
import ConfigSvgComponent from '@/components/Editor/ConfigSvgComponent.vue';
import Edge from '@/models/Edge';
import ModelElementType from '@/models/ModelElementType';
import Vue from 'vue';

@Component({
  components: { ConfigSvgComponent },
})
export default class PreviewCanvas extends Vue {
  @Prop({
    required: true,
  })
  protected modelConfig!: ModelConfig;

  @Prop({
    required: true,
  })
  protected modelElements!: ModelElement[];

  protected viewPort: Point = new Point(0, 0);

  protected scale = 1;

  protected loading = true;

  @Watch('modelElements')
  protected handleModelElementsChange(newVal: ModelElement[], oldVal: ModelElement[]): void {
    if (newVal.length !== oldVal.length) {
      this.handleChange();
    }
  }

  public mounted(): void {
    this.handleChange();
  }

  protected handleChange(): void {
    this.loading = true;
    window.setTimeout(() => {
      this.moveViewportToElements();
      window.setTimeout(() => {
        this.loading = false;
      }, 200);
    }, 200);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  protected handleDragScrollMove(e: any): void {
    this.viewPort = new Point(this.viewPort.x - e.detail.deltaX, this.viewPort.y - e.detail.deltaY);
  }

  protected handleMouseWheelScroll(event: WheelEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.deltaY <= 0) {
      // zoom in
      this.scale = Math.round((this.scale + 0.1) * 100) / 100;
      // limit to maximum of 300%
      if (this.scale > 3) {
        this.scale = 3;
      }
    } else {
      // zoom out
      this.scale = Math.round((this.scale - 0.1) * 100) / 100;
      // limit to minimum of 10%
      if (this.scale < 0.1) {
        this.scale = 0.1;
      }
    }
  }

  protected getComponentPrototype(modelElement: ModelElement): string {
    if (this.modelConfig) {
      let type: ModelElementType | null | undefined = null;
      if (modelElement instanceof Node) {
        type = this.modelConfig.getNodeType(modelElement.type);
      } else if (modelElement instanceof Edge) {
        type = this.modelConfig.getEdgeType(modelElement.type);
      }

      if (type) {
        return type.display;
      }
    }

    return '';
  }

  protected moveViewportToElements(): void {
    let minX = 99999;
    let minY = 99999;
    let maxX = 0;
    let maxY = 0;

    this.modelElements.forEach((modelElement) => {
      if (modelElement instanceof Node) {
        minX = Math.min(minX, modelElement.startPos.x);
        maxX = Math.max(maxX, modelElement.startPos.x + modelElement.bounds.width);
        minY = Math.min(minY, modelElement.startPos.y);
        maxY = Math.max(maxY, modelElement.startPos.y + modelElement.bounds.height);
      }
    });

    let viewportClientRect = (this.$refs.viewport as HTMLElement).getBoundingClientRect();

    let elementWidth = Math.abs(minX - maxX);
    let elementHeight = Math.abs(minY - maxY);

    this.viewPort.x = (minX - viewportClientRect.width / 2 + elementWidth / 2) * -1;
    this.viewPort.y = (minY - viewportClientRect.height / 2 + elementHeight / 2) * -1;
  }
}
