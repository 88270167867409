































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Model from '@/models/Model';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import ModelConfig from '@/models/ModelConfig';
import ModelService from '@/services/ModelService';
import SelectModelElementTable from '@/components/modals/ModelImportModelElementModal/SelectModelElementTable.vue';
import SelectedModelElementTable from '@/components/modals/ModelImportModelElementModal/SelectedModelElementTable.vue';
import Node from '@/models/Node';
import Edge from '@/models/Edge';
import { UpdateModelMixin } from '@/mixins/UpdateModelMixin';
import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';
import ModelElement from '@/models/ModelElement';
import NodeHelpers from '@/serializer/NodeHelpers';
import ModelElementSelectModal from '@/components/modals/ModelImportModelElementModal/ModelElementSelectModal.vue';
import { ChangeTargetTypeEvent, PreviewModelelementEvent } from '@/interfaces/Events';
import AttributeType from '@/models/AttributeType';
import Attribute from '@/models/Attribute';
import ConfirmEdgeModal, { OkEvent } from '@/components/modals/ModelImportModelElementModal/ConfirmEdgeModal.vue';
import { deserialize, serialize } from 'typescript-json-serializer';
import { fixNodeReferencesOfEdges, fixNodeReferencesOfNodeParentId } from '@/serializer/helpers';

@Component({
  components: { ConfirmEdgeModal, ModelElementSelectModal, SelectedModelElementTable, SelectModelElementTable },
})
export default class ModelImportModelElementModal extends mixins(Toasts, UpdateModelMixin) {
  public static MODAL_ID = 'modal-model-import-modelelement';

  @Prop({
    required: true,
    default: () => null,
  })
  protected targetModel!: Model | null;

  @Prop({
    required: true,
  })
  protected projectId!: string;

  @Prop({
    required: true,
  })
  protected targetModelConfig!: ModelConfig;

  @Prop({
    required: true,
  })
  protected sourceModelConfig!: ModelConfig;

  protected selectedModelOption: { text: string; value: string } | null = null;
  protected selectedModelElements: ModelElement[] = [];

  protected existingModels: Model[] = [];
  protected availableSearchTerm = '';

  protected linkToOriginal = true;

  protected confirmImportModelElement: ModelElement | null = null;
  protected confirmImportEdge: Edge | null = null;

  @Watch('model')
  protected handleModelChange(): void {
    console.log('Model changed');
  }

  @Watch('selectedModel')
  protected handleSelectedModelChange(newVal: Model): void {
    // reset search if model changes
    this.availableSearchTerm = '';
    this.$emit('select-model', { model: newVal });
  }

  get title(): string {
    return 'Import Model Elements';
  }

  get id(): string {
    return ModelImportModelElementModal.MODAL_ID;
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected handleShow(): void {
    this.loadResults();
  }

  protected handleImport(): void {
    this.importSelectedModelElements();
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected loadResults(): void {
    this.setLoading(true);

    ModelService.getByProjectId(parseInt(this.projectId))
      .then((models) => {
        this.existingModels = models;
        this.setLoading(false);
      })
      .catch(() => {
        this.showToast('Error', 'Failed loading models.', 'danger');
        this.setLoading(false);
      });
  }

  get availableModelOptions(): { [key: string]: string | null }[] {
    return this.existingModels
      .filter((model) => this.targetModel && model.id !== this.targetModel.id)
      .map((linkableModel) => {
        return {
          value: linkableModel.id ?? null,
          text: linkableModel.name,
        };
      });
  }

  get selectedModel(): Model | null {
    if (this.selectedModelOption) {
      let model = this.existingModels.find(
        (model) => this.selectedModelOption && model.id === this.selectedModelOption.value
      );
      if (model) {
        return model;
      }
    }

    return null;
  }

  get preparedSelectedElements(): ModelElement[] {
    return this.selectedModelElements;
  }

  get modelElements(): { [key: string]: string | number | null }[] {
    let modelElements: { [key: string]: string | number | null }[] = [];

    if (this.selectedModel) {
      if (this.selectedModel.nodes) {
        let nodes = this.selectedModel.nodes
          // Filter nodes that are already on model
          .filter((node) => this.targetModel && this.targetModel.nodes.findIndex((n) => n.id === node.id) === -1)
          // filter already selected model elements
          .filter((node) => this.selectedModelElements.map((me) => me.id).indexOf(node.id) === -1)
          // filter by search term
          .filter(
            (node) =>
              NodeHelpers.getName(node).toLowerCase().indexOf(this.availableSearchTerm.toLowerCase()) > -1 ||
              node.description.toLowerCase().indexOf(this.availableSearchTerm.toLowerCase()) > -1 ||
              node.id.toLowerCase().indexOf(this.availableSearchTerm.toLowerCase()) > -1
          )
          .map((node) => {
            return {
              id: node.id,
              type: node.type,
              name: NodeHelpers.getName(node),
            };
          });
        modelElements = Array.prototype.concat(modelElements, nodes);
      }

      if (this.selectedModel.edges) {
        let edges = this.selectedModel.edges
          // Filter nodes that are already on model
          .filter((edge) => this.targetModel && this.targetModel.edges.findIndex((n) => n.id === edge.id) === -1)
          // filter already selected model elements
          .filter((edge) => this.selectedModelElements.map((me) => me.id).indexOf(edge.id) === -1)
          // filter by search term
          .filter(
            (edge) =>
              NodeHelpers.getName(edge).toLowerCase().indexOf(this.availableSearchTerm.toLowerCase()) > -1 ||
              edge.description.toLowerCase().indexOf(this.availableSearchTerm.toLowerCase()) > -1 ||
              edge.id.toLowerCase().indexOf(this.availableSearchTerm.toLowerCase()) > -1
          )
          .map((edge) => {
            return {
              id: edge.id,
              type: edge.type,
              name: NodeHelpers.getName(edge),
            };
          });
        modelElements = Array.prototype.concat(modelElements, edges);
      }
    }

    return modelElements;
  }

  protected importSelectedModelElements(): void {
    if (this.targetModel && this.selectedModel) {
      this.selectedModelElements.forEach((modelElement) => {
        this.importModelElement(modelElement);
      });

      // remove parent ids that are not existant
      this.targetModel.nodes.forEach((node) => {
        if (this.targetModel && node.parentId) {
          let parent = this.targetModel.nodes.find((n) => n.id === node.parentId);
          if (!parent) {
            node.parentId = null;
          }
        }
      });

      let targetConfigTypes = this.targetModelConfig.types;

      // drop attributes that do not exist in target config
      this.targetModel.modelElements.forEach((modelElement) => {
        let type = targetConfigTypes.find((type) => modelElement.type === type.typeName);

        if (type) {
          let attributes: Attribute[] = [];
          modelElement.attributes.forEach((attribute) => {
            if (type) {
              let attributeType = type.attributeTypes.find(
                (attributeType: AttributeType) => attributeType.variableName === attribute.name
              );

              if (!attributeType) {
                console.log('Remove attribute', attribute.name, modelElement);
              } else {
                attributes.push(attribute);
              }
            }
          });

          modelElement.attributes = attributes;
        } else {
          console.error('Type not found for model element', modelElement, targetConfigTypes);
        }
      });

      // add attributes that exist in target config but not in source config
      this.targetModel.modelElements.forEach((modelElement) => {
        let type = targetConfigTypes.find((type) => modelElement.type === type.typeName);

        if (type) {
          type.attributeTypes.forEach((attributeType) => {
            let attribute = modelElement.attributes.find((attribute) => attribute.name === attributeType.variableName);
            if (!attribute) {
              switch (attributeType.type.toLowerCase()) {
                case 'boolean':
                  console.log('Add boolean attribute', attributeType);
                  modelElement.attributes.push(new Attribute(attributeType.variableName, 'false'));
                  break;
                case 'number':
                case 'integer':
                  console.log('Add number attribute', attributeType);
                  modelElement.attributes.push(new Attribute(attributeType.variableName, '0'));
                  break;
                case 'string':
                case 'text':
                default:
                  console.log('Add string attribute', attributeType);
                  modelElement.attributes.push(new Attribute(attributeType.variableName, attributeType.displayName));
                  break;
              }
            }
          });
        } else {
          console.error('Type not found for model element', modelElement, targetConfigTypes);
        }
      });

      // also add bidirectional edge
      this.targetModel.edges.forEach((edge: Edge) => {
        let edgeType = this.targetModelConfig.edgeTypes.find((edgeType) => edgeType.typeName === edge.type);
        if (this.targetModel && edgeType && edgeType.bidirectional) {
          let bidirectionalEdge = deserialize(serialize(edge), Edge);
          let originalFromId = bidirectionalEdge.connectsFromId;
          bidirectionalEdge.generateId();

          bidirectionalEdge.connectsFromId = bidirectionalEdge.connectsToId;
          bidirectionalEdge.connectsToId = originalFromId;

          if (this.targetModel.edges.indexOf(bidirectionalEdge) === -1) {
            console.log('Add Edge (bidirectional)');
            this.targetModel.edges.push(bidirectionalEdge);
          }
        }
      });

      console.log('Update model');

      // update target model
      this.updateModel(
        {
          category: ModelHistoryCategoryEnum.OTHER,
          comment:
            'Imported model elements ["' +
            this.selectedModelElements.map((me) => me.id).join(', ') +
            '"] from model "' +
            this.selectedModel.name +
            '"',
        },
        this.targetModel
      ).then((result) => {
        if (result instanceof Model) {
          this.selectedModelElements = [];
          this.selectedModelOption = null;

          this.setCurrentModel(fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(result)));

          // hide modal
          this.$bvModal.hide(ModelImportModelElementModal.MODAL_ID);
        }
      });

      if (this.linkToOriginal) {
        // add link from source to target model
        this.updateModel(
          {
            category: ModelHistoryCategoryEnum.OTHER,
            comment: 'Added link to ' + this.targetModel.name,
          },
          this.selectedModel
        );
      }
    }
  }

  protected importModelElement(modelElement: ModelElement): void {
    if (this.targetModel && this.selectedModel) {
      console.info('Import ModelElement', modelElement);
      if (modelElement) {
        // remove id if linking is disabled
        if (!this.linkToOriginal) {
          let oldId = modelElement.id;
          modelElement.generateId();
          // change all parent ids and edge ids
          this.selectedModelElements.forEach((me) => {
            if (me instanceof Node && me !== modelElement && me.parentId === oldId) {
              me.parentId = modelElement.id;
            } else if (me instanceof Edge) {
              if (me.connectsToId === oldId) {
                me.connectsToId = modelElement.id;
              } else if (me.connectsFromId === oldId) {
                me.connectsFromId = modelElement.id;
              }
            }
          });
        } else if (this.targetModel.id && this.selectedModel.linkedModelIds.indexOf(this.targetModel.id) === -1) {
          this.selectedModel.linkedModelIds.push(this.targetModel.id);
        }

        // copy modelElement into current model
        if (modelElement instanceof Node) {
          if (this.targetModel.nodes.indexOf(modelElement) === -1) {
            console.log('Add Node');
            this.targetModel.nodes.push(modelElement);
          }
        } else if (modelElement instanceof Edge) {
          if (this.targetModel.edges.indexOf(modelElement) === -1) {
            console.log('Add Edge');
            this.targetModel.edges.push(modelElement);
          }
        }
      }
    }
  }

  protected handlePreviewModelelement(modelelementEvent: PreviewModelelementEvent): void {
    this.$emit('preview-modelelement', modelelementEvent);
  }

  protected handleSelectModelelements({
    modelElementDetails,
  }: {
    modelElementDetails: { id: string; type: string; name: string }[];
  }) {
    modelElementDetails.forEach((modelElementDetail) => {
      if (this.selectedModel) {
        let modelElement = this.selectedModel.modelElements.find(
          (modelElement) => modelElement.id === modelElementDetail.id
        );
        if (modelElement) {
          var children = this.getChildren(modelElement);
          // don't show children and edge modal on bulk select
          if (children.length > 0 && modelElementDetails.length === 1) {
            // show modal
            this.confirmImportModelElement = modelElement;
            this.$bvModal.show(ModelElementSelectModal.MODAL_ID);
          } else if (modelElement instanceof Edge && modelElementDetails.length === 1) {
            // show edge modal
            this.confirmImportEdge = modelElement;
            this.$bvModal.show(ConfirmEdgeModal.MODAL_ID);
          } else {
            // add directly
            this.selectedModelElements.push(modelElement);
          }
        }
      }
    });
  }

  protected getChildren(modelElement: ModelElement): ModelElement[] {
    return this.selectedModel ? this.selectedModel.nodes.filter((node) => node.parentId === modelElement.id) : [];
  }

  protected handleDeselectModelelements({
    modelElementDetails,
  }: {
    modelElementDetails: { id: string; type: string; name: string }[];
  }) {
    modelElementDetails.forEach((modelElementDetails) => {
      if (this.selectedModel) {
        let selectedModel = this.selectedModel;
        // find edge connecting another node
        let edges = this.selectedModelElements
          // reduce to edges
          .filter((selectedModelElement) => {
            return selectedModel.edges.findIndex((edge) => edge.id === selectedModelElement.id) > -1;
          })
          // reduce to connecting edges
          .filter((selectedModelElement) => {
            return (
              selectedModel.edges.findIndex(
                (edge) =>
                  edge.id === selectedModelElement.id &&
                  (edge.connectsToId === modelElementDetails.id || edge.connectsFromId === modelElementDetails.id)
              ) > -1
            );
          })
          // map to modelelement
          .map((selectedModelElement) => {
            return selectedModel.edges.find((edge) => edge.id === selectedModelElement.id);
          });

        if (edges.length > 0) {
          edges.forEach((edge) => {
            if (edge) {
              let index = this.selectedModelElements.findIndex((me) => me.id === edge.id);
              if (index > -1) {
                this.selectedModelElements.splice(index, 1);
              }
            }
          });
        }
      }

      let index = this.selectedModelElements.findIndex((me) => me.id === modelElementDetails.id);
      if (index > -1) {
        this.selectedModelElements.splice(index, 1);
      }
    });
  }

  protected handleSelect({ modelElements }: { modelElements: ModelElement[] }): void {
    this.selectedModelElements = this.selectedModelElements.concat(modelElements);
  }

  protected handleChangeTargetType(data: ChangeTargetTypeEvent): void {
    let modelElement: ModelElement | undefined = this.selectedModelElements.find(
      (modelElement) => modelElement.id === data.modelElement.id
    );
    if (modelElement) {
      modelElement.type = data.targetType;
    }
  }

  protected handleEdgeConfirm(event: OkEvent): void {
    if (this.selectedModelElements.indexOf(event.edge) === -1) {
      this.selectedModelElements.push(event.edge);
    }
    if (this.selectedModelElements.indexOf(event.edge.connectsFrom) === -1) {
      this.selectedModelElements.push(event.edge.connectsFrom);
    }
    if (this.selectedModelElements.indexOf(event.edge.connectsTo) === -1) {
      this.selectedModelElements.push(event.edge.connectsTo);
    }
  }

  get atLeastOneTypeMissing(): boolean {
    for (let i in this.selectedModelElements) {
      if (Object.prototype.hasOwnProperty.call(this.selectedModelElements, i)) {
        let modelElement = this.selectedModelElements[i];

        let targetType = this.targetModelConfig.types.find((type) => type.typeName === modelElement.type);

        if (!targetType) {
          return true;
        }
      }
    }

    return false;
  }
}
