














































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Sidebar from '@/components/Editor/Sidebar.vue';
import SidebarLeft from '@/components/review/SidebarLeft.vue';
import EditorCanvas from '@/components/Editor/EditorCanvas.vue';
import { Action, namespace } from 'vuex-class';
import { mapActions, mapState } from 'vuex';
import Model from '@/models/Model';
import {
  filterTemporaryDrawables,
  fixNodeReferencesOfEdges,
  fixNodeReferencesOfNodeParentId,
} from '@/serializer/helpers';
import ModelService from '@/services/ModelService';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import Point from '@/models/Point';
import AttributeBar from '@/components/Editor/AttributeBar.vue';
import SelectedModelElement from '@/models/SelectedModelElement';
import ModelScope from '@/models/ModelScope';
import ModelScopeService from '@/services/ModelScopeService';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import ReviewCanvasReadOnly from '@/components/Editor/ReviewCanvasReadOnly.vue';
import ReviewCanvas from '@/components/Editor/ReviewCanvas.vue';
import ModelUpdateModal from '@/components/modals/ModelUpdateModal.vue';
import EditorSidebarRight from '@/components/Editor/EditorSidebarRight.vue';
import ModelHistoryList from '@/components/Editor/ModelHistoryList.vue';
import ModelHistory from '@/models/ModelHistory';
import ModelHistoryService from '@/services/ModelHistoryService';
import ModelAnalysisModal from '@/components/modals/ModelAnalysisModal.vue';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';
import { BvModalEvent } from 'bootstrap-vue';
import { deserialize, serialize } from 'typescript-json-serializer';
import CanvasToolbarTop from '@/components/Editor/CanvasToolbar/CanvasToolbarTop.vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ModelLinkDetailModal from '@/components/modals/ModelLinkDetailModal.vue';
import ModelCopyModal from '@/components/modals/ModelCopyModal.vue';
import ModelImportModelElementModal from '@/components/modals/ModelImportModelElementModal.vue';
import { ModelMixin } from '@/mixins/ModelMixin';
import { UpdateModelMixin } from '@/mixins/UpdateModelMixin';
import ModelElementPreviewModal from '@/components/modals/ModelImportModelElementModal/ModelElementPreviewModal.vue';
import ModelElementImport from '@/components/Editor/ModelElementImport/ModelElementImport.vue';
import { CopyModelMixin } from '@/mixins/CopyModelMixin';
import { LoadModelConfig, ToastMessage } from '@/mixins/LoadModelConfig';
import ModelConfig from '@/models/ModelConfig';
import RunPipelinesModal from '@/components/modals/RunPipelinesModal.vue';
import PipelineExecution from '@/models/pipelines/PipelineExecution';
import PipelineService from '@/services/PipelineService';
import { LayersMixins } from '@/mixins/LayersMixins';

const modelEditor = namespace('modelEditor');
const modelEditorReviewStates = namespace('modelEditorReviewStates');
const project = namespace('project');

@Component({
  components: {
    ModelElementImport,
    ModelElementPreviewModal,
    ModelImportModelElementModal,
    ModelCopyModal,
    ModelLinkDetailModal,
    CanvasToolbarTop,
    ModelHistoryList,
    EditorSidebarRight,
    ModelUpdateModal,
    ReviewCanvas,
    ReviewCanvasReadOnly,
    AttributeBar,
    EditorCanvas,
    Sidebar,
    SidebarLeft,
    ModelAnalysisModal,
    RunPipelinesModal,
  },
  computed: {
    ...mapState('modelEditor', ['currentModel', 'currentConfig', 'selectedElements']),
    ...mapState('modelEditorReviewStates', ['reviewAssignments']),
  },
  methods: {
    ...mapActions('modelEditor', {
      setCurrentModel: 'setCurrentModel',
      setCurrentConfig: 'setCurrentConfig',
      setCurrentModelBackgroundImage: 'setCurrentModelBackgroundImage',
    }),
    ...mapActions('modelEditorReviewStates', ['setModelId', 'setReviewAssignments', 'setModelScopes']),
  },
  beforeRouteUpdate(to, from, next): void {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    (this as Editor).isModelChanged().then((modelChanged) => {
      if (modelChanged) {
        if ((this as Editor).confirmUnsavedChanges()) {
          next();
        }
      } else {
        next();
      }
    });
  },
  beforeRouteLeave(to, from, next): void {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    (this as Editor).isModelChanged().then((modelChanged) => {
      if (modelChanged) {
        if ((this as Editor).confirmUnsavedChanges()) {
          window.removeEventListener('beforeunload', (this as Editor).handleBeforeUnload);
          (this as Editor).setReviewAssignments([]);
          next();
        }
      } else {
        next();
      }
    });
  },
})
export default class Editor extends mixins(
  Toasts,
  ModelMixin,
  UpdateModelMixin,
  CopyModelMixin,
  LoadModelConfig,
  LayersMixins
) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, undefined, assignment);
    return permission != null && permission;
  }

  @modelEditor.Action
  protected setCurrentModelBackgroundImage!: (backgroundImage: ModelBackgroundImage | undefined) => void;

  @modelEditorReviewStates.Action
  protected setModelId!: (modelId: string | undefined) => void;

  @modelEditorReviewStates.Action
  protected setReviewAssignments!: (reviewAssignments: ReviewAssignment[]) => void;

  @modelEditorReviewStates.Action
  protected setModelScopes!: (modelScopes: ModelScope[]) => void;

  @project.State
  protected currentProjectId!: number | undefined;
  @project.Action
  protected setCurrentProjectId!: (projectId: number | undefined) => void;

  @Action('setSelectedElements', { namespace: 'modelEditor' })
  protected setSelectedElements!: (elements: SelectedModelElement[]) => void;

  protected reviewAssignments!: ReviewAssignment[];

  protected showReviewState = false;
  protected showLeftSidebar = true;
  protected showRightSidebar = false;
  protected activeTabIndex = 0;
  protected linkedModelNames: { [key: string]: string } = {};

  protected selectedLinkDetailsModel: Model | null = null;
  protected selectedModelToLink: string | null = null;
  protected existingModels: Model[] = [];

  protected unfinishedPipelineExecutions: PipelineExecution[] = [];

  @Prop({
    default: '',
    required: true,
  })
  protected modelId!: string;

  @Prop({
    default: '',
    required: true,
  })
  protected projectId!: number;

  @Watch('modelId')
  public handleChangeModelId(): void {
    this.$root.$data.loading = true;
    this.reviewAssignments = [];
    // clear layers when model changes
    this.clearEditorLayers();
    this.loadModel(this.modelId);
  }

  @Watch('isUnderReview')
  public handleChangeUnderReview(newVal: boolean): void {
    if (newVal) {
      this.showReviewState = true;
    }
  }

  @Watch('selectedElements')
  public handleSelectedElementsChange(newVal: SelectedModelElement[]): void {
    // force show sidebar if element gets selected
    if (newVal.length > 0) {
      this.showRightSidebar = true;
    }
    Vue.nextTick(() => {
      setTimeout(() => (this.activeTabIndex = 0), 50);
    });
  }

  public selectedElements!: SelectedModelElement[];
  protected modelHistoryItems: ModelHistory[] = [];

  get areElementSelected(): boolean {
    if (this.selectedElements) {
      return this.selectedElements.length > 0;
    }
    return false;
  }

  protected syncLeftSidebarVisibility(visible: boolean): void {
    this.showLeftSidebar = visible;
  }

  protected syncRightSidebarVisibility(visible: boolean): void {
    this.showRightSidebar = visible;
  }

  @Watch('projectId')
  protected handleProjectIdChanged(newVal: number): void {
    this.setCurrentProjectId(newVal);
  }

  public mounted(): void {
    if (this.projectId) {
      // clear layers when model changes
      this.clearEditorLayers();
      this.setCurrentProjectId(this.projectId);
    }
  }

  public created(): void {
    this.$root.$data.loading = true;
    this.loadModel(this.modelId);
    this.loadExistingModels();
    this.checkForUnfinishedPipelineExecutions();

    if (!this.currentProjectId) {
      this.setCurrentProjectId(this.projectId);
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload);
    // window.onbeforeunload = (e) => {
    //   e.returnValue = this.confirmUnsavedChangesString;
    // };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  private handleBeforeUnload(event: any): void {
    event.returnValue = this.confirmUnsavedChangesString;
  }

  protected handleCopyModel(data: { name: string; createLink: boolean; openModel: boolean }): void {
    if (this.currentProjectId) {
      this.copyModel(this.currentModel, this.currentProjectId, data)
        .then(() => {
          this.$bvModal.hide(ModelCopyModal.MODAL_ID);
        })
        .catch((error) => {
          let modal = this.$refs.modelCopyModal as ModelCopyModal;
          switch (error.response.status) {
            default:
              modal.showNewNameValidationError(error.response.data.message);
              break;
          }
        });
    }
  }

  protected loadModelHistory(): void {
    if (this.currentModel && this.currentModel.id) {
      ModelHistoryService.getByModelId(this.currentModel.id).then((modelHistoryItems) => {
        this.modelHistoryItems = modelHistoryItems;
      });
    }
  }

  public loadConfig(): void {
    this.loadModelConfig(this.currentModel)
      .then((config) => {
        if (config instanceof ModelConfig) {
          this.setCurrentConfig(config);
        }
        this.$root.$data.loading = false;
      })
      .catch((toastMessage: ToastMessage) => {
        this.$root.$data.loading = false;
        this.$router
          .replace({
            name: 'projectDetails',
            params: { projectId: this.projectId.toString() },
          })
          .then(() => {
            this.showToast(toastMessage.title, toastMessage.msg, 'danger');
          });
      });
  }

  public loadLinkedModel(modelId: string): void {
    if (hasPermission(PossibleAction.CAN_GET_MODEL)) {
      ModelService.getById(modelId).then((model) => {
        this.selectedLinkDetailsModel = fixNodeReferencesOfEdges(model);
      });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  public loadModel(modelId: string): void {
    if (hasPermission(PossibleAction.CAN_GET_MODEL)) {
      ModelService.getById(modelId).then((model) => {
        this.setCurrentModel(fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(model)));
        Vue.nextTick(() => {
          this.loadConfig();
          this.loadModelHistory();
          if (
            hasPermission(PossibleAction.CAN_GET_SCOPE) &&
            hasPermission(PossibleAction.CAN_GET_ASSIGNMENT_FOR_EDITOR)
          ) {
            this.loadModelScopes().then((modelscopes) => {
              this.setModelScopes(modelscopes);
              modelscopes.forEach((modelscope) => {
                if (this.currentProjectId) {
                  this.loadReviewAssignmentsOfModelScope(this.currentProjectId, modelscope).then((reviewAssignment) => {
                    this.setReviewAssignments([...this.reviewAssignments, ...reviewAssignment]);
                  });
                }
              });

              if (modelscopes.length === 0) {
                this.setReviewAssignments([]);
              }
            });
          }
          if (hasPermission(PossibleAction.CAN_GET_IMAGE)) {
            if (model.hasImage) {
              this.loadImage().then((imageHref: string) => {
                this.setCurrentModelBackgroundImage(new ModelBackgroundImage(imageHref, new Point(0, 0)));
              });
            }
          }
        });
      });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  /**
   * Returns promise with image url on success
   */
  protected loadImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.currentModel && this.currentModel.id) {
        ModelService.getImageByModelId(this.currentModel.id).then((response) => {
          resolve(response);
        });
      } else {
        reject(false);
      }
    });
  }

  protected loadModelScopes(): Promise<ModelScope[]> {
    return new Promise<ModelScope[]>((resolve, reject) => {
      if (this.currentModel.id) {
        resolve(ModelScopeService.getAllByModelId(this.currentModel.id));
      } else {
        reject();
      }
    });
  }

  protected loadReviewAssignmentsOfModelScope(projectId: number, modelScope: ModelScope): Promise<ReviewAssignment[]> {
    return new Promise<ReviewAssignment[]>((resolve, reject) => {
      if (modelScope.id) {
        resolve(ReviewAssignmentService.getAllByModelScopeId(projectId, modelScope.id));
      } else {
        reject();
      }
    });
  }

  protected handleUpdateModel(
    e: { originalEvent?: BvModalEvent; category: ModelHistoryCategoryEnum; comment: string },
    model?: Model
  ): Promise<void> {
    return this.updateModel(e, model).then((updatedModel) => {
      this.setSelectedElements([]);
      if (updatedModel instanceof Model) {
        this.setCurrentModel(fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(updatedModel)));
      }
      this.loadModelHistory();
    });
  }

  beforeDestroy(): void {
    this.setCurrentModel(undefined);
    this.setCurrentConfig(undefined);
    this.setCurrentModelBackgroundImage(undefined);
  }

  protected confirmUnsavedChanges(): boolean {
    return window.confirm(this.confirmUnsavedChangesString);
  }

  get confirmUnsavedChangesString(): string {
    return 'Are you sure you want to leave?\nYou have unsaved changes.';
  }

  get isUnderReview(): boolean {
    return (
      this.reviewAssignments.find((reviewAssignment) => {
        return reviewAssignment.state == AssignmentStateEnum.IN_PROCESS;
      }) !== undefined || this.unfinishedPipelineExecutions.length > 0
    );
  }

  returnToProject(): void {
    this.$router.replace({
      name: 'projectDetails',
      params: { projectId: this.projectId.toString() },
    });
  }

  protected toggleReviewState(): void {
    this.showReviewState = !this.showReviewState;
  }

  isModelChanged(): Promise<boolean> {
    return ModelService.getById(this.modelId).then(
      (model) => {
        // Create deep copy of currentModel
        const currentModelCopy = deserialize(serialize(this.currentModel), Model);
        return (
          this.calculateHash(
            JSON.stringify(serialize(this.removeEdgeConnectionPoints(filterTemporaryDrawables(currentModelCopy))))
          ) != this.calculateHash(JSON.stringify(serialize(this.removeEdgeConnectionPoints(model))))
        );
      },
      function () {
        // Return true in any case where we cannot verify that model wasn't changed, e.g. promise cant be resolved
        return true;
      }
    );
  }

  protected calculateHash(input: string): number {
    let h = 0,
      i = 0;
    const l = input.length;
    if (l > 0) while (i < l) h = ((h << 5) - h + input.charCodeAt(i++)) | 0;
    return h;
  }

  protected removeEdgeConnectionPoints(model: Model): Model {
    // Remove all connect points from edges to make hashing more stable
    for (const edge of model.edges) {
      edge.startPos = new Point(0, 0);
      edge.endPos = new Point(0, 0);
    }
    return model;
  }

  protected getLinkedModelName(linkedModelId: string): string {
    if (this.currentModel && Object.keys(this.linkedModelNames).length !== this.currentModel.linkedModelIds.length) {
      ModelService.getByProjectId(this.projectId).then((models) => {
        const linkedModels = models.filter((model) => {
          if (model.id) {
            return this.currentModel.linkedModelIds.indexOf(model.id) > -1;
          }
          return false;
        });
        linkedModels.forEach((model) => {
          if (model.id) {
            this.linkedModelNames[model.id] = model.name;
          }
        });
      });
    }

    return this.linkedModelNames[linkedModelId] ?? linkedModelId;
  }

  protected openLinkDetailModal(linkedModelId: string): void {
    this.loadLinkedModel(linkedModelId);
    this.$bvModal.show('modal-model-link-detail');
  }

  protected removeLinkToModel(linkedModelId: string): void {
    let confirmation = window.confirm('Do you really want to remove link to model? This action cannot be undone!');

    if (confirmation) {
      this.currentModel.linkedModelIds.splice(this.currentModel.linkedModelIds.indexOf(linkedModelId), 1);
      this.updateModel({
        comment: 'Removed Link to ' + linkedModelId,
        category: ModelHistoryCategoryEnum.OTHER,
      });
    }
  }

  /**
   * Filter existing models to exclude self (current model) and already linked models
   */
  get linkableModels(): Model[] {
    let models: Model[] = [];

    this.existingModels.forEach((model) => {
      if (model.id !== this.currentModel.id && this.currentModel.linkedModelIds.indexOf(model.id ?? null) === -1) {
        models.push(model);
      }
    });

    return models;
  }

  protected loadExistingModels(): void {
    ModelService.getByProjectId(this.projectId)
      .then((models) => {
        this.existingModels = models;
      })
      .catch(() => {
        this.showToast('Error', 'Failed loading models.', 'danger');
      });
  }

  protected createLink(): void {
    if (this.selectedModelToLink && this.currentModel && this.currentModel.linkedModelIds) {
      this.currentModel.linkedModelIds.push(this.selectedModelToLink);
      this.updateModel({
        comment: 'Added Link to ' + this.selectedModelToLink,
        category: ModelHistoryCategoryEnum.OTHER,
      }).then(() => {
        this.selectedModelToLink = null;
      });
    }
  }

  protected checkForUnfinishedPipelineExecutions(): void {
    PipelineService.getExecutions(false, this.modelId, this.projectId, null)
      .then((unfinishedExecutions) => {
        this.unfinishedPipelineExecutions = unfinishedExecutions;
      })
      .catch(() => {
        this.showToast('Error', 'Failed to load pipeline executions.', 'danger');
      });
  }
}
