





















import { Component } from 'vue-property-decorator';
import ModelImportModelElementModal from '../../modals/ModelImportModelElementModal.vue';
import { mixins } from 'vue-class-component';
import { ModelMixin } from '@/mixins/ModelMixin';
import ModelElementPreviewModal from '@/components/modals/ModelImportModelElementModal/ModelElementPreviewModal.vue';
import Model from '@/models/Model';
import Vue from 'vue';
import ModelElementSelectModal from '@/components/modals/ModelImportModelElementModal/ModelElementSelectModal.vue';
import ModelConfig from '@/models/ModelConfig';
import { LoadModelConfig, ToastMessage } from '@/mixins/LoadModelConfig';
import { PreviewModelelementEvent } from '@/interfaces/Events';
import { deserialize, serialize } from 'typescript-json-serializer';
import { ProjectId } from '@/mixins/ProjectMixins';

@Component({
  components: { ModelElementSelectModal, ModelElementPreviewModal, ModelImportModelElementModal },
})
export default class ModelElementImport extends mixins(ModelMixin, ProjectId, LoadModelConfig) {
  protected selectedModel: Model | null = null;
  protected selectedModelElementId: string | null = null;

  protected selectedModelConfig: ModelConfig | null = null;
  protected previewModelConfig: ModelConfig | null = null;

  protected handleSelectModel({ model }: { model: Model }): void {
    this.selectedModel = model;
    this.loadModelConfig(model)
      .then((config) => {
        if (config instanceof ModelConfig) {
          this.selectedModelConfig = config;
        }
        this.$root.$data.loading = false;
      })
      .catch((toastMessage: ToastMessage) => {
        this.$root.$data.loading = false;
        this.showToast(toastMessage.title, toastMessage.msg, 'danger');
      });
  }

  protected handlePreviewModelElement({ modelElementId, modelConfig }: PreviewModelelementEvent): void {
    this.$bvModal.show(ModelElementPreviewModal.MODAL_ID);
    Vue.nextTick(() => {
      this.previewModelConfig = modelConfig;
      this.selectedModelElementId = modelElementId;
    });
  }

  get clonedCurrentModel(): Model {
    return deserialize(serialize(this.currentModel), Model);
  }
}
