import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { ModelActionEnum } from '@/enums/ModelActionEnum';
import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';
import { deserializeModelHistoryAction, deserializeModelHistoryCategory } from '@/serializer/EnumHelpers';

@Serializable()
export default class ModelHistory {
  @JsonProperty('id')
  private readonly _id: string;
  @JsonProperty('userId')
  private readonly _userId: string;
  @JsonProperty('modelId')
  private readonly _modelId: string;
  @JsonProperty({
    name: 'action',
    onDeserialize: deserializeModelHistoryAction,
  })
  private readonly _action: ModelActionEnum;
  @JsonProperty({
    name: 'category',
    onDeserialize: deserializeModelHistoryCategory,
  })
  private readonly _category: ModelHistoryCategoryEnum | null = null;
  @JsonProperty('comment')
  private readonly _comment: string | null = null;
  @JsonProperty('timestamp')
  private readonly _timestamp: Date;

  constructor(
    id: string,
    userId: string,
    modelId: string,
    action: ModelActionEnum,
    category: ModelHistoryCategoryEnum,
    comment: string,
    timestamp: Date
  ) {
    this._id = id;
    this._userId = userId;
    this._modelId = modelId;
    this._action = action;
    this._category = category;
    this._comment = comment;
    this._timestamp = timestamp;
  }

  get id(): string {
    return this._id;
  }

  get userId(): string {
    return this._userId;
  }

  get modelId(): string {
    return this._modelId;
  }

  get action(): ModelActionEnum {
    return this._action;
  }

  get category(): ModelHistoryCategoryEnum | null {
    return this._category;
  }

  get comment(): string | null {
    return this._comment;
  }

  get timestamp(): Date {
    return this._timestamp;
  }
}
