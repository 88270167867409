import { render, staticRenderFns } from "./ModelElementSelectModal.vue?vue&type=template&id=2ed6cd96&scoped=true&"
import script from "./ModelElementSelectModal.vue?vue&type=script&lang=ts&"
export * from "./ModelElementSelectModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed6cd96",
  null
  
)

export default component.exports