


























import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Model from '@/models/Model';
import ModelElement from '@/models/ModelElement';
import Node from '@/models/Node';
import Edge from '@/models/Edge';

@Component
export default class ModelLinkDetailModal extends mixins(Toasts) {
  @Prop({
    required: true,
  })
  protected firstModel!: Model | null;

  @Prop({
    required: true,
  })
  protected secondModel!: Model | null;

  get title(): string {
    return 'Link details: ' + (this.firstModel?.name ?? '') + ' <-> ' + (this.secondModel?.name ?? '') + '';
  }

  get matchedModelElements(): {
    own: ModelElement;
    other: ModelElement;
  }[] {
    let matchedModelElements: {
      own: ModelElement;
      other: ModelElement;
    }[] = [];

    if (this.firstModel && this.secondModel) {
      if (this.firstModel.nodes) {
        this.firstModel.nodes.forEach((node) => {
          let nodeInSecondModel = (this.secondModel?.nodes ?? []).find((node2) => node.id === node2.id);

          if (nodeInSecondModel) {
            matchedModelElements.push({
              own: node,
              other: nodeInSecondModel,
            });
          }
        });
      }

      if (this.firstModel.edges) {
        this.firstModel.edges.forEach((edge) => {
          let edgeInSecondModel = (this.secondModel?.edges ?? []).find((edge2) => edge.id === edge2.id);

          if (edgeInSecondModel) {
            matchedModelElements.push({
              own: edge,
              other: edgeInSecondModel,
            });
          }
        });
      }
    }
    return matchedModelElements;
  }

  protected getModelElementName(modelElement: ModelElement): string {
    let name = modelElement?.type ?? '';

    if (modelElement instanceof Node) {
      let node = modelElement as Node;
      let nameAttribute = node.attributes.filter((attribute) => attribute.name === 'name');

      if (nameAttribute.length > 0) {
        name = nameAttribute[0].value;
      }
    } else if (modelElement instanceof Edge) {
      return (
        'Edge: ' +
        this.getModelElementName(modelElement.connectsFrom) +
        ' - ' +
        this.getModelElementName(modelElement.connectsTo)
      );
    }

    return name;
  }
}
