


































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { Action, namespace, State } from 'vuex-class';
import SelectedModelElement from '@/models/SelectedModelElement';
import { v4 as uuidv4 } from 'uuid';
import ModelConfig from '@/models/ModelConfig';
import ModelElementType from '@/models/ModelElementType';
import Model from '@/models/Model';
import EdgeType from '@/models/EdgeType';
import BackgroundImageUpload from '@/components/Editor/BackgroundImageUpload.vue';
import ModelElement from '@/models/ModelElement';
import TextAnnotation from '@/models/TextAnnotation';
import Position from '@/models/Position';
import Point from '@/models/Point';
import TextAnnotationDrawable from '@/models/drawables/TextAnnotationDrawable';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import { SVG } from '@svgdotjs/svg.js';
import SVGHelper from '@/serializer/SVGHelper';
import PathFinding, { PathFindingMechanism, PathFindingMechanismOption } from '@/pathfinding/PathFinding';
import ModelCopyModal from '@/components/modals/ModelCopyModal.vue';
import ModelImportModelElementModal from '@/components/modals/ModelImportModelElementModal.vue';
import { mixins } from 'vue-class-component';
import { LayersMixins } from '@/mixins/LayersMixins';

const modelEditor = namespace('modelEditor');
const drawEdges = namespace('drawEdges');

@Component({
  components: { BackgroundImageUpload },
  computed: {
    ...mapState('modelEditor', ['scale', 'selectedGroup', 'selectedElements', 'currentConfig', 'currentModel']),
    ...mapState('drawEdges', ['connectModeEnabled']),
  },
  methods: {
    ...mapActions('modelEditor', {
      setScale: 'setScale',
      setSelectedGroup: 'setSelectedGroup',
      clearSelectedElements: 'clearSelectedElements',
    }),
    ...mapActions('drawEdges', {
      setConnectModeEnabled: 'setConnectModeEnabled',
      setConnectionType: 'setConnectionType',
      setSourceNode: 'setSourceNode',
      setTargetNode: 'setTargetNode',
    }),
  },
})
export default class Sidebar extends mixins(LayersMixins, Vue) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, undefined, assignment);
    return permission != null && permission;
  }

  @Prop({ required: true, default: true })
  protected isUnderReview!: boolean;

  @Prop({ required: true, default: true })
  protected showReviewState!: boolean;

  protected toggleReviewState(): void {
    this.$emit('update:show-review-state', !this.showReviewState);
  }

  protected returnToProject(): void {
    this.$emit('return-to-project');
  }

  protected showAnalysisModal(): void {
    this.$bvModal.show('analysis-modal');
  }

  protected showRunPipelinesModal(): void {
    this.$bvModal.show('run-pipelines-modal');
  }

  protected saveModel(): void {
    this.$bvModal.show('modal-save-model');
  }

  protected showCopyModelModal(): void {
    this.$bvModal.show(ModelCopyModal.MODAL_ID);
  }

  protected showImportModelElementModal(): void {
    this.$bvModal.show(ModelImportModelElementModal.MODAL_ID);
  }

  get scaleOptions(): { text: string; value: number }[] {
    return [
      { text: '50%', value: 0.5 },
      { text: '75%', value: 0.75 },
      { text: '100%', value: 1 },
      { text: '125%', value: 1.25 },
      { text: '150%', value: 1.5 },
      { text: '175%', value: 1.75 },
      { text: '200%', value: 2 },
      { text: '300%', value: 3 },
    ];
  }

  get areElementSelected(): boolean {
    if (this.selectedElements) {
      return this.selectedElements.length === 1;
    }
    return false;
  }

  get areEnoughElementsSelected(): boolean {
    if (this.selectedElements) {
      return this.selectedElements.length > 1;
    }
    return false;
  }

  get annotationAllowed(): boolean {
    return (
      this.selectedElements.length === 1 && !(this.selectedElements[0].modelElement instanceof TextAnnotationDrawable)
    );
  }

  get localScaleHumanReadable(): number {
    let scale = 1 / this.scale;
    return Math.round(scale * 100) / 100;
  }

  /**
   * Injected from store
   */
  public scale!: number;
  /**
   * Injected from store
   */
  public currentConfig!: ModelConfig;
  public currentModel!: Model;
  /**
   * Intermediate variable to store current scale to prevent direct object access
   * of object in vuex store.
   */
  public localScale = 1;
  public selectedGroup!: string | boolean;
  public selectedElements!: SelectedModelElement[];

  /**
   * Injected from store
   */
  private connectModeEnabled!: boolean;

  @modelEditor.Action
  public setScale!: (val: number) => void;

  @modelEditor.Action
  public setSelectedGroup!: (group: string | boolean) => void;

  @modelEditor.Action
  public clearSelectedElements!: () => void;

  @drawEdges.Action
  private setConnectModeEnabled!: (value: boolean) => void;

  @drawEdges.Action
  private setConnectionType!: (type: string | undefined) => void;
  @drawEdges.Action
  private setSourceNode!: (node: ModelElement | undefined) => void;
  @drawEdges.Action
  private setTargetNode!: (node: ModelElement | undefined) => void;

  private connectModeReset(): void {
    this.setConnectModeEnabled(false);
    this.setSourceNode(undefined);
    this.setTargetNode(undefined);
    this.setConnectionType(undefined);
  }

  @Watch('scale')
  public watchScale(newVal: number): void {
    this.localScale = newVal;
  }

  @Watch('localScale')
  public watchLocalScale(newVal: number): void {
    this.setScale(newVal);
  }

  public handleUngroup(): void {
    this.selectedElements.forEach((element: SelectedModelElement) => {
      element.modelElement.groupId = undefined;
    });

    this.setSelectedGroup(false);
  }

  public handleGroup(): void {
    const groupid = uuidv4();
    this.selectedElements.forEach((element: SelectedModelElement) => {
      element.modelElement.groupId = groupid;
    });

    this.clearSelectedElements();
    this.setSelectedGroup(groupid);
  }

  protected getSvgViewboxOfElement(element: ModelElementType): string {
    const draw = SVG('#svgjs-container');
    let viewBox = '0 0 ';
    if (draw) {
      const svg = draw.svg(this.getDisplayOfElement(element));
      if (svg) {
        const width = parseFloat(svg.attr('width'));
        if (width && !isNaN(width)) {
          viewBox += width + ' ';
        } else {
          viewBox += '100 ';
        }
        const height = svg.attr('height');
        if (height && !isNaN(height)) {
          viewBox += height + ' ';
        } else {
          viewBox += '100';
        }
      }

      draw.clear();
    }

    return viewBox;
  }

  protected resetMarkerPositions(): void {
    this.$root.$emit('reset-markers');
  }

  getDisplayOfElement(element: ModelElementType): string {
    let displaySVG: string = element.display;
    element.attributeTypes.forEach((attr) => {
      if (attr.variableName.toLowerCase() === 'height' || attr.variableName.toLowerCase() === 'width') {
        displaySVG = displaySVG.split('{{attribute_' + attr.variableName + '}}').join('100%');
      } else if (attr.variableName === 'name') {
        displaySVG = displaySVG.split('{{attribute_' + attr.variableName + '}}').join(attr.variableName);
      } else {
        displaySVG = displaySVG.split('{{attribute_' + attr.variableName + '}}').join(attr.variableName);
      }

      // replace edge defs marker id
      displaySVG = SVGHelper.createDynamicEdgePathIds(displaySVG);
    });
    if (displaySVG.indexOf('<svg') === 0) {
      return displaySVG;
    }

    return '<svg viewBox="0 0 120 120" style="width: 100%; height: 100%;">' + displaySVG + '</svg>';
  }

  getPossibleEdgeTypes(): EdgeType[] {
    if (this.currentConfig && this.selectedElements.length === 1) {
      return this.currentConfig.edgeTypes.filter((value) => {
        if (value.connects.length === 0) {
          return true;
        }
        for (const con of value.connects) {
          if (con.from === this.selectedElements[0].modelElement.type) {
            return true;
          }
        }
        return false;
      });
    }
    return [];
  }

  protected startDragDrawable(e: Event, type: string): void {
    this.startDrag(e, 'new_element_drawable', type);
  }

  protected startDragNode(e: Event, type: string): void {
    this.startDrag(e, 'new_element_node', type);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  protected startDrag(e: any, datatype: string, type: string): void {
    e.dataTransfer.setData(datatype, type);
  }

  protected handleStartConnectMode(event: Event, edgeType: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.setConnectionType(edgeType);
    this.setConnectModeEnabled(true);
  }

  protected handleStopConnectMode(): void {
    this.connectModeReset();
  }

  protected addAnnotation(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.selectedElements.length === 1) {
      this.selectedElements[0].modelElement.annotations.push(
        new TextAnnotation(
          uuidv4(),
          '',
          new Position(
            new Point(
              this.selectedElements[0].modelElement.startPos.x - 150,
              this.selectedElements[0].modelElement.startPos.y - 150
            ),
            new Point(
              this.selectedElements[0].modelElement.startPos.x - 50,
              this.selectedElements[0].modelElement.startPos.y - 50
            )
          )
        )
      );
    }
  }

  protected handleScaleChange(value: string): void {
    let number = parseFloat(value);
    if (isNaN(number)) {
      number = 1;
    }

    // max of 10
    number = Math.min(number, 10);
    // min of 1
    number = Math.max(number, 0.33);

    this.setScale(1 / number);
  }

  protected changeEdgeRenderingOption(newValue: PathFindingMechanism): void {
    this.currentModel.edgeRenderingType = newValue;
  }

  get edgeRenderingOptions(): PathFindingMechanismOption[] {
    return PathFinding.getPathFindingMechanismOptions();
  }

  get edgeRenderingType(): PathFindingMechanism {
    if (this.currentModel) {
      return this.currentModel.edgeRenderingType;
    }

    return PathFindingMechanism.LINEAR;
  }
}
