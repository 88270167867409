







































































import { Component, Prop } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';
import ModelConfig from '@/models/ModelConfig';

@Component
export default class SelectModelElementTable extends Vue {
  @Prop({
    required: false,
    default: () => 1,
  })
  protected page!: number;

  @Prop({
    required: false,
    default: () => 5,
  })
  protected perPage!: number;

  @Prop({
    required: false,
    default: () => null,
  })
  protected totalRows!: number | null;

  @Prop({
    required: true,
  })
  protected modelElements!: { [key: string]: string | number | null }[];

  @Prop({
    required: true,
  })
  protected sourceConfig!: ModelConfig;

  protected internalPerPage = 5;
  protected internalCurrentPage = 1;

  protected selectedRows: { id: string; name: string; type: string }[] = [];

  get paginatedModelElements(): { [key: string]: string | number | null }[] {
    return this.modelElements.slice(
      this.internalPerPage * (this.internalCurrentPage - 1),
      this.internalPerPage * this.internalCurrentPage
    );
  }

  get paginationOptions(): { [key: string]: string | number | null }[] {
    return [
      {
        value: 5,
        text: '5',
      },
      {
        value: 10,
        text: '10',
      },
      {
        value: 25,
        text: '25',
      },
      {
        value: 50,
        text: '50',
      },
      {
        value: 100,
        text: '100',
      },
    ];
  }

  get internalTotalRows(): number {
    if (this.totalRows) {
      return this.totalRows;
    }

    return this.modelElements.length;
  }

  protected selectElement(modelElementDetails: { id: string; name: string; type: string }): void {
    this.$emit('select-modelelements', { modelElementDetails: [modelElementDetails] });
  }

  protected previewModelElement(modelElementDetails: { id: string; name: string; type: string }): void {
    this.$emit('preview-modelelement', { modelElementId: modelElementDetails.id, modelConfig: this.sourceConfig });
  }

  protected bulkSelect(): void {
    this.$emit('select-modelelements', { modelElementDetails: this.selectedRows });
    this.selectedRows = [];
  }
}
