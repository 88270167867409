import { render, staticRenderFns } from "./ModelImportModelElementModal.vue?vue&type=template&id=4ff1c047&scoped=true&"
import script from "./ModelImportModelElementModal.vue?vue&type=script&lang=ts&"
export * from "./ModelImportModelElementModal.vue?vue&type=script&lang=ts&"
import style0 from "./ModelImportModelElementModal.vue?vue&type=style&index=0&id=4ff1c047&scoped=true&lang=css&"
import style1 from "./ModelImportModelElementModal.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff1c047",
  null
  
)

export default component.exports