































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelHistory from '@/models/ModelHistory';
import moment from 'moment';
import { ModelActionEnum, modelActionEnumLabels } from '@/enums/ModelActionEnum';
import { ModelHistoryCategoryEnum, modelHistoryCategoryEnumLabels } from '@/enums/ModelHistoryCategoryEnum';
import UserInfo from '@/models/users/UserInfo';
import { State } from 'vuex-class';
import WysiwygEditor from '@/components/WysiwygEditor.vue';

@Component({
  components: { WysiwygEditor },
})
export default class ModelHistoryList extends Vue {
  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  @Prop({
    required: true,
    default: () => [],
  })
  protected items!: ModelHistory[];

  @Prop({
    required: false,
    default: 5,
  })
  protected itemsPerPage!: number;

  protected limit = 5;

  protected selectedActionFilter: ModelActionEnum | null = null;
  protected selectedCategoryFilter: ModelHistoryCategoryEnum | null = null;

  protected formatTimestamp(timestamp: Date): string {
    return moment(timestamp).format('DD.MM.YYYY HH:mm:ss');
  }

  protected handleLoadMore(): void {
    this.limit += this.itemsPerPage;
  }

  get moreItemsAvailable(): boolean {
    if (this.items) {
      return this.limit < this.items.length;
    }

    return false;
  }

  get filteredItems(): ModelHistory[] {
    return this.items.filter((item) => {
      let matchAction = true;
      if (this.selectedActionFilter !== null) {
        matchAction = item.action === this.selectedActionFilter;
      }

      let matchCategory = true;
      if (this.selectedCategoryFilter !== null) {
        matchCategory = item.category === this.selectedCategoryFilter;
      }

      return matchAction && matchCategory;
    });
  }

  get paginatedItems(): ModelHistory[] {
    return this.filteredItems.slice(0, this.limit);
  }

  get actionOptions(): [{ value: ModelActionEnum | null; text: string }] {
    const actionOptions: [{ value: ModelActionEnum | null; text: string }] = [
      {
        value: null,
        text: 'All',
      },
    ];

    this.items.forEach((item) => {
      if (!actionOptions.find((action) => action.value === item.action)) {
        actionOptions.push({
          value: item.action,
          text: modelActionEnumLabels[item.action],
        });
      }
    });

    return actionOptions;
  }

  get categoryOptions(): [{ value: ModelHistoryCategoryEnum | null; text: string }] {
    const categoryOptions: [{ value: ModelHistoryCategoryEnum | null; text: string }] = [
      {
        value: null,
        text: 'All',
      },
    ];

    this.items.forEach((item) => {
      if (item.category !== null && !categoryOptions.find((category) => category.value === item.category)) {
        categoryOptions.push({
          value: item.category,
          text: modelHistoryCategoryEnumLabels[item.category],
        });
      }
    });

    return categoryOptions;
  }

  get isActionFiltered(): boolean {
    return this.selectedActionFilter !== null;
  }

  get isCategoryFiltered(): boolean {
    return this.selectedCategoryFilter !== null;
  }

  protected getHeader(item: ModelHistory): string {
    return this.formatTimestamp(item.timestamp) + ' by ' + this.getName(item.userId);
  }

  protected hasComment(item: ModelHistory): boolean {
    return item.comment !== null && item.comment.length > 0;
  }

  protected getCategoryLabel(category: ModelHistoryCategoryEnum | null): string {
    if (category !== null) {
      return modelHistoryCategoryEnumLabels[category];
    }

    return '';
  }

  protected getActionLabel(action: ModelActionEnum): string {
    return modelActionEnumLabels[action];
  }

  protected resetFilter(): void {
    this.selectedActionFilter = null;
    this.selectedCategoryFilter = null;
  }

  private getName(uid: string): string {
    return (
      this.availableUsers
        .filter((value) => {
          if (value.uid === uid) {
            return value.fullName;
          }
        })
        .pop()?.fullName ?? ''
    );
  }
}
