



















import { Component, Prop, Vue } from 'vue-property-decorator';
import PreviewCanvas from '../../Editor/PreviewCanvas.vue';
import ModelElement from '@/models/ModelElement';
import Edge from '@/models/Edge';

export interface OkEvent {
  edge: Edge;
}

@Component({
  components: { PreviewCanvas },
})
export default class ConfirmEdgeModal extends Vue {
  public static MODAL_ID = 'modal-confirm-edge';

  @Prop({
    required: true,
  })
  protected edge!: ModelElement;

  get id(): string {
    return ConfirmEdgeModal.MODAL_ID;
  }

  protected handleOk(): void {
    this.$emit('ok', { edge: this.edge });
  }
}
