import Component, { mixins } from 'vue-class-component';
import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';
import Model from '@/models/Model';
import { hasPermission } from '@/auth/AuthService';
import ModelService from '@/services/ModelService';
import ModelUpdate from '@/models/ModelUpdate';
import {
  fixNodeReferencesOfEdges,
  fixNodeReferencesOfNodeParentId,
  syncAttributesOfBidirectionalEdges,
} from '@/serializer/helpers';
import ModelHistoryInput from '@/models/ModelHistoryInput';
import { Toasts } from '@/mixins/ToastMixins';
import { ModelMixin } from '@/mixins/ModelMixin';
import { PossibleAction } from '@/auth/PossibleAction';
import ModelConfig from '@/models/ModelConfig';

@Component({})
export class UpdateModelMixin extends mixins(Toasts, ModelMixin) {
  updateModel(
    details: { category: ModelHistoryCategoryEnum; comment: string },
    model?: Model,
    config?: ModelConfig
  ): Promise<Model | string> {
    if (!model) {
      model = this.currentModel;
    }

    if (!config) {
      config = this.currentConfig;
    }

    if (hasPermission(PossibleAction.CAN_UPDATE_MODEL)) {
      this.$root.$data.loading = true;
      return ModelService.update(
        new ModelUpdate(
          syncAttributesOfBidirectionalEdges(model, config),
          new ModelHistoryInput(details.comment, details.category)
        )
      )
        .then((updatedModel) => {
          this.showToast('Successfully updated', "Model '" + updatedModel.name + "' successfully updated", 'success');

          return updatedModel;
        })
        .catch((backendError) => {
          let msg,
            title = 'Failed to update';
          if (backendError.response.status === 404) {
            msg = 'Could not find the model.';
          } else if (backendError.response.status === 400) {
            msg = backendError.response.data.message;
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Could not update model: ' + backendError.response.status;
          }
          this.showToast(title, msg, 'danger');

          return backendError;
        })
        .finally(() => (this.$root.$data.loading = false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }

    return new Promise<Model | string>((resolve) => resolve(''));
  }
}
