


















import { Component } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { namespace } from 'vuex-class';
import Model from '@/models/Model';
import ModelService from '@/services/ModelService';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import Point from '@/models/Point';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

const modelEditor = namespace('modelEditor');

@Component({
  computed: mapState('modelEditor', ['currentModel', 'currentModelBackgroundImage']),
  methods: {
    ...mapActions('modelEditor', ['setCurrentModelBackgroudImage']),
  },
})
export default class Sidebar extends mixins(Toasts) {
  /**
   * Injected from store
   */
  protected currentModel!: Model;

  /**
   * Injected from store
   */
  protected currentModelBackgroundImage!: ModelBackgroundImage;

  @modelEditor.Action
  protected setCurrentModelBackgroundImage!: (backgroundImage: ModelBackgroundImage | undefined) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected file: any = null;

  protected uploadImage(): void {
    if (this.currentModel && this.currentModel.id) {
      this.$root.$data.loading = true;
      const formData = new FormData();
      formData.append('file', this.file);
      ModelService.saveImageToModel(this.currentModel.id, formData)
        .then(() => {
          this.file = null;
          this.loadImage()
            .then((imageHref: string) => {
              this.showToast('Successfully saved', 'Background Image was successfully saved', 'success');
              this.setCurrentModelBackgroundImage(new ModelBackgroundImage(imageHref, new Point(0, 0)));
            })
            .catch(() => {
              this.showToast('Failed to reload', 'Oops, something went wrong', 'danger');
            })
            .finally(() => (this.$root.$data.loading = false));
        })
        .catch((backendError) => {
          this.$root.$data.loading = false;
          let msg;
          if (backendError.response.status === 404) {
            msg = 'Could not find the model.';
          } else {
            msg = 'Could not upload the image: ' + backendError.response.status;
          }
          this.showToast('Failed to update', msg, 'danger');
        });
    } else {
      this.showToast('Failed to upload', 'Save the model first.', 'danger');
    }
  }

  protected removeImage(): void {
    if (this.currentModel && this.currentModel.id) {
      if (this.currentModelBackgroundImage) {
        this.$bvModal
          .msgBoxConfirm('Do you want to delete the background image?', {
            title: 'Delete Background Image',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            hideHeaderClose: true,
            centered: true,
          })
          .then((confirmationResponse) => {
            if (confirmationResponse && this.currentModel.id) {
              this.$root.$data.loading = true;
              ModelService.deleteImageByModelId(this.currentModel.id)
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    this.showToast('Successfully deleted', 'Background Image was successfully deleted', 'success');
                    this.setCurrentModelBackgroundImage(undefined);
                    this.currentModel.hasImage = false;
                  }
                })
                .catch(() => {
                  this.showToast('Failed to delete', 'Oops, something went wrong', 'danger');
                })
                .finally(() => (this.$root.$data.loading = false));
            }
          })
          .catch(() => {
            this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
          });
      }
    }
  }

  /**
   * TODO refactor
   * Returns promise with image url on success
   */
  protected loadImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.currentModel && this.currentModel.id) {
        resolve(ModelService.getImageByModelId(this.currentModel.id));
      } else {
        reject(false);
      }
    });
  }

  protected formatNames(files: File[]): string {
    if (files.length === 1) {
      const name = files[0].name.substring(0, Math.min(15, files[0].name.length));
      return name.length === files[0].name.length ? name : name + '...';
    } else {
      return files.length + ' Files selected';
    }
  }
}
