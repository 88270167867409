































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';

@Component
export default class ChildrenSelectTable extends Vue {
  @Prop({
    required: false,
    default: () => 1,
  })
  protected page!: number;

  @Prop({
    required: false,
    default: () => 5,
  })
  protected perPage!: number;

  @Prop({
    required: false,
    default: () => null,
  })
  protected totalRows!: number | null;

  @Prop({
    required: true,
  })
  protected modelElements!: { id: string; name: string; type: string }[];

  @Prop({
    required: false,
  })
  protected selectedRows: { id: string; name: string; type: string }[] = [];

  protected internalPerPage = 5;
  protected internalCurrentPage = 1;

  protected allSelected = false;

  @Watch('allSelected')
  protected handleAllSelectedChanged(newVal: boolean) {
    if (newVal) {
      this.modelElements.forEach((modelElement) => {
        this.handleSelectModelelement(modelElement, true);
      });
    } else {
      this.modelElements.forEach((modelElement) => {
        this.handleSelectModelelement(modelElement, false);
      });
    }
  }

  get paginatedModelElements(): { id: string; name: string; type: string }[] {
    return this.modelElements.slice(
      this.internalPerPage * (this.internalCurrentPage - 1),
      this.internalPerPage * this.internalCurrentPage
    );
  }

  get paginationOptions(): { [key: string]: string | number | null }[] {
    return [
      {
        value: 5,
        text: '5',
      },
      {
        value: 10,
        text: '10',
      },
      {
        value: 25,
        text: '25',
      },
      {
        value: 50,
        text: '50',
      },
      {
        value: 100,
        text: '100',
      },
    ];
  }

  get internalTotalRows(): number {
    if (this.totalRows) {
      return this.totalRows;
    }

    return this.modelElements.length;
  }

  protected selectElement(modelElementDetails: { id: string; name: string; type: string }): void {
    this.$emit('select-modelelements', { modelElementDetails: [modelElementDetails] });
  }

  protected previewModelElement(modelElementDetails: { id: string; name: string; type: string }): void {
    this.$emit('preview-modelelement', { modelElementId: modelElementDetails.id });
  }

  protected handleSelectModelelement(
    modelElementDetails: { id: string; name: string; type: string },
    checked: boolean
  ): void {
    if (checked) {
      this.$emit('select-modelelement', { modelElementDetails: modelElementDetails });
    } else {
      this.$emit('deselect-modelelement', { modelElementDetails: modelElementDetails });
    }
  }
}
