






























import { Component, Vue } from 'vue-property-decorator';
import ModelHistoryService from '@/services/ModelHistoryService';
import { ModelHistoryCategoryEnum, modelHistoryCategoryEnumLabels } from '@/enums/ModelHistoryCategoryEnum';
import { BvModalEvent } from 'bootstrap-vue';

@Component
export default class ModelUpdateModal extends Vue {
  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        this.commentErrorMessage = 'Comment must have a maximum length of 5000 characters.';
        return false;
      }
    }
    return state;
  }

  protected categories: string[] = [];
  protected selectedCategory: ModelHistoryCategoryEnum | null = null;
  protected comment = '';

  protected categoryValidationState: boolean | null = null;
  protected categoryErrorMessage: string | null = null;

  protected commentValidationState: boolean | null = null;
  protected commentErrorMessage: string | null = null;

  get categoryOptions(): { value: ModelHistoryCategoryEnum | null; text: string }[] {
    const chooseElement: { value: ModelHistoryCategoryEnum | null; text: string } = {
      value: null,
      text: 'Choose category...',
    };
    return [chooseElement].concat(
      this.categories.map((category, index) => {
        return {
          value: index,
          text: modelHistoryCategoryEnumLabels[index],
        };
      })
    );
  }

  created(): void {
    this.loadCategories();
  }

  protected validate(): boolean {
    let valid = true;
    if (this.selectedCategory === null) {
      this.categoryValidationState = false;
      this.categoryErrorMessage = 'Category is required';
      valid = false;
    } else {
      this.categoryValidationState = true;
      this.categoryErrorMessage = null;
    }

    if (this.comment.trim().length === 0 && this.selectedCategory === ModelHistoryCategoryEnum.OTHER) {
      this.commentValidationState = false;
      this.commentErrorMessage = 'Comment is required (max. Length 5000).';
      valid = false;
    } else {
      this.commentValidationState = true;
    }

    if (this.comment.length > 5000) {
      this.commentValidationState = false;
      this.commentErrorMessage = 'Comment must have a maximum length of 5000 characters.';
      valid = false;
    }

    return valid;
  }

  protected handleOk(event: BvModalEvent): void {
    if (this.validate()) {
      this.$emit('ok', { originalEvent: event, category: this.selectedCategory, comment: this.comment.trim() });
      this.reset();
    } else {
      event.preventDefault();
    }
  }

  protected loadCategories(): void {
    ModelHistoryService.getCategories().then((categories) => {
      this.categories = categories;
    });
  }

  protected reset(): void {
    this.selectedCategory = null;
    this.comment = '';
    this.categoryValidationState = null;
    this.commentValidationState = null;
  }
}
