import ModelHistory from '@/models/ModelHistory';
import MainService from '@/services/MainService';
import { deserialize } from 'typescript-json-serializer';

export default class ModelHistoryService {
  private static mainURL = '/modelhistory';

  public static getByModelId(modelId: string): Promise<ModelHistory[]> {
    return MainService.get(this.mainURL + '/' + modelId).then((response) => {
      return response.data.map((item) => deserialize(item, ModelHistory));
    });
  }

  public static getCategories(): Promise<string[]> {
    return MainService.get(this.mainURL + '/categories').then((response) => {
      return response.data;
    });
  }
}
