














































































import { Component, Prop } from 'vue-property-decorator';
import { Vue } from 'vue-property-decorator';
import ModelConfig from '@/models/ModelConfig';
import { MultiselectOption } from '@/interfaces/Multiselect';
import ModelElement from '@/models/ModelElement';
import Node from '@/models/Node';
import EdgeType from '@/models/EdgeType';
import NodeType from '@/models/NodeType';
import Edge from '@/models/Edge';
import NodeHelpers from '@/serializer/NodeHelpers';
import Model from '@/models/Model';

@Component
export default class SelectedModelElementTable extends Vue {
  @Prop({
    required: false,
    default: () => 1,
  })
  protected page!: number;

  @Prop({
    required: false,
    default: () => 5,
  })
  protected perPage!: number;

  @Prop({
    required: false,
    default: () => null,
  })
  protected totalRows!: number | null;

  @Prop({
    required: true,
  })
  protected modelElements!: ModelElement[];

  @Prop({
    required: true,
  })
  protected targetConfig!: ModelConfig;

  protected internalPerPage = 5;
  protected internalCurrentPage = 1;

  get paginatedModelElements(): ModelElement[] {
    return this.modelElements.slice(
      this.internalPerPage * (this.internalCurrentPage - 1),
      this.internalPerPage * this.internalCurrentPage
    );
  }

  get paginationOptions(): { [key: string]: string | number | null }[] {
    return [
      {
        value: 5,
        text: '5',
      },
      {
        value: 10,
        text: '10',
      },
      {
        value: 25,
        text: '25',
      },
      {
        value: 50,
        text: '50',
      },
      {
        value: 100,
        text: '100',
      },
    ];
  }

  get internalTotalRows(): number {
    if (this.totalRows) {
      return this.totalRows;
    }

    return this.modelElements.length;
  }

  protected previewModelElement(modelElement: ModelElement): void {
    this.$emit('preview-modelelement', { modelElementId: modelElement.id, modelConfig: this.targetConfig });
  }

  protected deselectElement(modelElement: ModelElement): void {
    this.$emit('deselect-modelelements', {
      modelElementDetails: [{ id: modelElement.id, name: NodeHelpers.getName(modelElement), type: modelElement.type }],
    });
  }

  protected changeTargetType(modelElement: ModelElement, targetType: MultiselectOption): void {
    this.$emit('change-target-type', {
      modelElement,
      targetType: targetType.value,
    });
  }

  protected hasInvalidTargetType(modelElement: ModelElement): boolean {
    return this.findTargetTypeOption(modelElement) === null;
  }

  protected findTargetTypeOption(modelElement: ModelElement): MultiselectOption | null {
    let targetTypeOption = this.getAvailableTargetTypeOptions(modelElement).find(
      (option) => option.value === modelElement.type
    );

    if (targetTypeOption) {
      return targetTypeOption;
    }

    return null;
  }

  protected getAvailableTargetTypeOptions(modelElement: ModelElement): MultiselectOption[] {
    if (!this.targetConfig) {
      return [];
    }

    if (modelElement instanceof Node) {
      return this.getTypeOptions(this.targetConfig.nodeTypes);
    } else if (modelElement instanceof Edge) {
      return this.getTypeOptions(this.targetConfig.edgeTypes);
    }

    return [];
  }

  protected getTypeOptions(types: (NodeType | EdgeType)[]): MultiselectOption[] {
    return types.map((type) => {
      return {
        text: type.typeName,
        value: type.typeName,
      };
    });
  }

  protected getModelElementName(modelElement: ModelElement): string {
    return NodeHelpers.getName(modelElement);
  }
}
